<template>
  <div class="wenhua">
    <Header :bg="bg" ref="header"></Header>
    <div class="x"></div>
    <div class="box">
      <div class="title-name">企业文化</div>
      <div class="list">
        <div class="son" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="text" v-html="item.text"></div>
          <img class="img" :src="item.src" alt="" />
        </div>
      </div>

      <div class="beijing">
        <div class="son" v-for="(item, index) in list" :key="index">
          <img class="bg" :src="item.bg" alt="" />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      bg: null,
      list: [
        {
          src: require("../../assets/imgs/guanyu/qiye (1).png"),
          title: "愿景",
          text: "做中国智慧城市解决方案<br>NO.1方阵科技公司",
          bg: require("../../assets/imgs/guanyu/wenhua (1).png"),
        },
        {
          src: require("../../assets/imgs/guanyu/qiye (2).png"),
          title: "目标",
          text: "创一流科技企业<br>跻身上市队列",
          bg: require("../../assets/imgs/guanyu/wenhua (2).png"),
        },
        {
          src: require("../../assets/imgs/guanyu/qiye (3).png"),
          title: "价值",
          text: "诚信、正直、敬业、创造",
          bg: require("../../assets/imgs/guanyu/wenhua (3).png"),
        },
        {
          src: require("../../assets/imgs/guanyu/qiye (4).png"),
          title: "理念",
          text: "客户至上、为企业谋发展、<br>为员工谋幸福",
          bg: require("../../assets/imgs/guanyu/wenhua (4).png"),
        },

        {
          src: require("../../assets/imgs/guanyu/qiye (5).png"),
          title: "合作",
          text: "找靠谱的人、做靠谱的事",
          bg: require("../../assets/imgs/guanyu/wenhua (5).png"),
        },

        {
          src: require("../../assets/imgs/guanyu/qiye (6).png"),
          title: "做事",
          text: "团队合作、拥抱变化<br>一次性把事情做成功",
          bg: require("../../assets/imgs/guanyu/wenhua (6).png"),
        },
      ],
    };
  },
  methods: {},
  mounted() {
    this.bg = "black";
    document.body.scrollTop = 0;
  },
  beforeDestroy() { },
};
</script>

<style lang="scss" scoped>
.wenhua {
  width: 1920px;

  .x {
    width: 100%;
    height: 64px;
  }

  .box {
    width: 100%;
    height: 864px;
    background: url("../../assets/imgs/guanyu/wenhuabg.png");
    background-size: 100% 100%;
    padding: 0 260px;
    padding-top: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-name {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 24px;
      color: #ffffff;
      position: absolute;
      top: 84px;
    }

    .list {
      width: 1116px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 105px;
      position: relative;
      justify-content: space-between;
      z-index: 10;

      .son {
        width: 298px;
        height: 212px;
        // background: url("../../assets/imgs/guanyu/baishe.png");
        // background-size: 100% 100%;
        background: rgba(185, 202, 255, 0.36);
        backdrop-filter: blur(3px);
        opacity: 1;
        border-radius: 10px;

        // margin: 0 55px;
        margin-bottom: 88px;
        padding: 0 30px;
        position: relative;
        top: 0;
        cursor: pointer;
        transition: all 0.3s linear;

        .title {
          font-size: 20px;
          font-family: Alibaba PuHuiTi;
          font-weight: normal;
          line-height: 28px;
          color: #ffffff;
          position: relative;
          top: 84px;
        }

        .text {
          font-size: 16px;
          font-family: Alibaba PuHuiTi Xi;
          font-weight: normal;
          line-height: 22px;
          color: #d2e1ff;
          position: relative;
          top: 96px;
        }

        .img {
          position: absolute;
          right: 13px;
          top: -7px;
          width: 65px;
          height: 113px;
        }

        .bg {
          width: 166px;
          height: 166px;
          position: absolute;
          top: -83px;
          left: -10px;
        }
      }

      .son:hover {
        top: -4px;
      }

      .son:hover .title {
        color: #ffffff;
      }

      .son:hover .text {
        color: #ffffff;
      }
    }

    .beijing {
      width: 1116px;

      display: flex;
      flex-wrap: wrap;

      padding-top: 80px;
      position: absolute;
      justify-content: space-between;
      // background-color: red;
      top: 148px;
      left: 402;

      .son {
        width: 298px;
        height: 212px;

        border-radius: 10px;

        // margin: 0 55px;
        margin-bottom: 88px;
        padding: 0 30px;
        position: relative;

        .bg {
          width: 166px;
          height: 166px;
          position: absolute;
          top: -83px;
          left: -10px;
        }
      }
    }
  }
}
</style>